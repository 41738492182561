var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"width":"100%","height":"600px","display":"flex","flex-direction":"row","justify-content":"center","align-items":"center"}},[_c('div',{},[_c('el-card',{staticClass:"box-card",attrs:{"shadow":"always","body-style":{
                height: '240px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'space-evenly',
            }}},[_c('div',{staticClass:"title"},[_vm._v(" "+_vm._s("Hi，" + _vm.cname + "邀请您加入")+" ")]),_c('el-button',{staticStyle:{"margin-top":"15px"},attrs:{"type":"primary","disabled":_vm.disabled},on:{"click":_vm.agreeInvite}},[_vm._v(" 同意加入 ")])],1)],1),(_vm.switchIdDialogVisible)?_c('switchId-dialog',{ref:"SwitchIdDialog"}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }